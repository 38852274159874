<template>
  <main class="main pa-5">
    <section class="invoice">
      <h1 class="bill__title ma-0">{{ $t('INVOICE.order_number') }}</h1>
      <ul class="bill__nav pa-0">
        <li class="mr-2"><a href="#">My account</a></li>
        <li class="mr-2"><a href="#">Invoice</a></li>
        <li><a href="#">Счета</a></li>
      </ul>



      <div class="invoice__content">
        <table id="invoice__bill">
          <tr>
            <th :key="item" v-for="item in this.$t('INVOICE.invoice_table')">{{
              item
            }}</th>
          </tr>
          <tr v-for="invoice in invoices" :key="invoice.id">
            <td class="cursor-pointer">{{ invoice.id }}</td>
            <td>{{ invoice.created_at }}</td>
            <td>Обработке</td>
            <td><span class="total">{{ invoice.order_sum }}</span></td>
            <td><span class="total">{{ invoice.invoice_sum }}</span></td>
            <td>
              <div
                @click="$router.push({ name: 'invoice' })"
                class="d-flex cursor-pointer"
              >
                <span class="circle"
                  ><v-icon size="14">mdi-link</v-icon></span
                >
                <span class="show">посмотреть</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('invoice')
export default {
  name: 'Invoice',
  methods: {
    ...mapActions(["fetchInvoice"])
  },
  computed: {
    ...mapState(["invoices"])
  },
  mounted() {
    this.fetchInvoice({order_id: this.$route.params.id})
  }
}
</script>

<style lang="scss" scoped>
.invoice__content {
  background-color: #ffffff;
  border-radius: 1px;
  padding: 27px;
  overflow-x: scroll;
}
.invoice__content::-webkit-scrollbar {
  width: 0;
}
#invoice__bill {
  width: 100%;
}

#invoice__bill tr td:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #423f3d;
}

#invoice__bill th {
  padding: 10px;
  text-align: start;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18%;
  color: #423f3d;
  white-space: nowrap;
}

#invoice__bill td {
  padding: 8px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #a4a4a4;
  white-space: nowrap;
}

.total {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #31343c;
}

#invoice__bill .show {
  color: #423f3d;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #cfebcc;
  margin-right: 9px;
}

#invoice__bill tr:first-child {
  height: 40px;
  background-color: #fbfbfb;
}

#invoice__bill tr:not(:first-child) {
  height: 50px;
  border-bottom: 1px solid #f5f5f5;
  &:hover {
    background-color: #fbfbfb;
  }
}

.bill__title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 1px;
  color: #31343c;
}

.bill__nav {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;

  li:not(:last-child) a {
    &::after {
      content: '';
      background-image: url('/img/icons/arrow-right-icon.svg');
      background-repeat: no-repeat;
      display: inline-block;
      width: 5px;
      height: 8px;
      margin-left: 7px;
    }
  }

  a {
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #818181;
  }
}
</style>
